import React from "react";
import { styled } from "goober";

export const NativeAdUnit = styled("section", React.forwardRef)`
  border-radius: var(--br);

  position: relative;
  display: flex;
  flex-direction: column;

  background-color: var(--shade7);

  .ad-title {
    pointer-events: none;
    border-top-right-radius: var(--br);
    border-bottom-left-radius: var(--br-sm);

    position: absolute;
    top: 0;
    right: 0;
    padding: 0 var(--sp-1_5);

    background-color: var(--shade6);
    color: var(--shade1);
  }
  .ad-image {
    border-radius: var(--br);
    border-top-right-radius: var(--br-lg);
    height: calc(var(--sp-1) * 56);

    background-color: hsl(220deg 61% 7%);
  }
  .sponsor-tag {
    color: var(--shade2);

    & > img {
      border-radius: var(--br);
      height: var(--sp-5);
      width: var(--sp-5);
    }
  }
  .ad-link {
    position: absolute;
    inset: 0;
  }

  .ad-link-btn {
    width: min-content;
    padding: 0 var(--sp-4);
  }
`;

export const CardAd = styled("div", React.forwardRef)`
  border-radius: var(--br);

  width: 100%;
  height: var(--sp-22);

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  padding: 0 var(--sp-5_5) 0;
  background-image: url(${({ $bgUrl }) => $bgUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-clip: border-box;

  & > .card-link {
    position: absolute;
    inset: 0;
  }
`;

export const ProfileTileAd = styled("div", React.forwardRef)`
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }

  a {
    position: absolute;
    inset: 0;
  }
`;
