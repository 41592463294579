import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/constants.mjs";
import * as Styled from "@/feature-battles/BattlesAds.style.jsx";
import {
  CLICK_ACTIONS,
  EventStatus,
  IMPRESSION_TARGETS,
  REF_PARAM,
} from "@/feature-battles/constants.mjs";
import { getEventStatus } from "@/feature-battles/hooks.mjs";
import type { BattlesEvent } from "@/feature-battles/model-events.mjs";
import { useBattlesAnalytics } from "@/feature-battles/use-battles-analytics.mjs";
import {
  getCurrentEvents,
  getEventDetailsRoute,
  sponsoredEvent,
} from "@/feature-battles/utils.mjs";
import type { MatchTileAd } from "@/shared/MatchTileAd.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function ProfileTileAdComponent({ gameSymbol }) {
  const {
    battles: { eventMap },
    settings: {
      battles: { hideEventPromos },
    },
  } = useSnapshot(readState);

  const event = useMemo(() => {
    const now = new Date();
    return getCurrentEvents(eventMap, gameSymbol).find(
      (event) =>
        sponsoredEvent(event) &&
        event.profileBannerImg &&
        getEventStatus(now, event.startAt, event.endAt) !== EventStatus.ENDED,
    );
  }, [eventMap, gameSymbol]);

  if (!event || hideEventPromos) return null;
  return <Tile {...event} />;
}

function Tile({ id, game, profileBannerImg, displayName }: BattlesEvent) {
  const { t } = useTranslation();
  const { impressionRef, handleClickEvent } = useBattlesAnalytics(
    id,
    IMPRESSION_TARGETS.bannerProfile,
  );

  const link = getEventDetailsRoute({ id, game });
  return (
    <Styled.ProfileTileAd ref={impressionRef}>
      <img src={`${appURLs.BATTLES_CDN}/${profileBannerImg}`} />
      <span className="visually-hidden">{displayName}</span>
      <a href={link} onClick={handleClickEvent()}>
        <span className="visually-hidden">
          {t("common:details", "Details")}
        </span>
      </a>
    </Styled.ProfileTileAd>
  );
}

export const ProfileTileAd: MatchTileAd = {
  id: "battles-profile-ad",
  priority: 3,
  Component: ProfileTileAdComponent,
};

export function BannerAdUnit({ callToActionUrl, eventId, eventPageHeaderImg }) {
  const { t } = useTranslation();

  const { impressionRef, handleClickEvent } = useBattlesAnalytics(
    eventId,
    IMPRESSION_TARGETS.bannerEventAdSponsor,
  );

  return (
    <Styled.NativeAdUnit
      onClick={handleClickEvent(CLICK_ACTIONS.details)}
      ref={impressionRef}
    >
      <a
        className="ad-link"
        href={`${callToActionUrl}?${REF_PARAM}`}
        target="_blank"
        rel="noreferrer"
      >
        <span className="visually-hidden">
          {t("battles:ad.link-label", "Go to event details")}
        </span>
      </a>
      <h3 className="type-caption ad-title">
        {t("battles:ad.ad-title", "Sponsored")}
      </h3>
      <img
        className="hero-img"
        src={`${appURLs.BATTLES_CDN}/${eventPageHeaderImg}`}
        alt={t("battles:ad.sponsor-image-alt", "Sponsor hero image")}
      />
    </Styled.NativeAdUnit>
  );
}

export function NativeAdUnit({
  adImg,
  adText,
  brandName,
  callToActionUrl,
  logoImg,
  eventId,
  sponsoredEvent,
}) {
  const { t } = useTranslation();

  const { impressionRef, handleClickEvent } = useBattlesAnalytics(
    eventId,
    IMPRESSION_TARGETS.nativeAdSponsor,
  );

  return (
    <Styled.NativeAdUnit
      onClick={handleClickEvent(CLICK_ACTIONS.details)}
      ref={impressionRef}
    >
      <a
        className="ad-link"
        href={`${callToActionUrl}?${REF_PARAM}`}
        target="_blank"
        rel="noreferrer"
      >
        <span className="visually-hidden">
          {t("battles:ad.link-label", "Go to event details")}
        </span>
      </a>
      {sponsoredEvent && (
        <h3 className="type-caption ad-title">
          {t("battles:ad.ad-title", "Sponsored")}
        </h3>
      )}
      {Boolean(adImg) && (
        <img
          className="ad-image"
          src={`${appURLs.BATTLES_CDN}/${adImg}`}
          alt={t("battles:ad.sponsor-image-alt", "Sponsor hero image")}
        />
      )}
      <div className="flex direction-column gap-4 pad-6">
        <p className="type-body2">{adText}</p>
        <span className="flex gap-2 align-center sponsor-tag type-caption">
          {Boolean(logoImg) && (
            <img
              src={`${appURLs.BATTLES_CDN}/${logoImg}`}
              alt={t("battles:ad.sponsor-logo-alt", "Sponsor logo")}
            />
          )}
          {t("battles:ad.sponsor-tag", "By {{brandName}}", { brandName })}
        </span>
        <Button
          as="a"
          className="ad-link-btn"
          href={`${callToActionUrl}?${REF_PARAM}`}
          target="_blank"
        >
          {t("battles:ad.cta", "Learn more")}
        </Button>
      </div>
    </Styled.NativeAdUnit>
  );
}

export const SponsoredJoinDisclaimer = ({
  adText,
  brandName,
  callToActionUrl,
  logoImg,
}: BattlesEvent["nativeAdUnit"]) => {
  const { t } = useTranslation();

  return (
    <div className="type-caption shade0 relative flex column gap-3">
      <span className="flex gap-2 align-center">
        <img
          className="br"
          height={20}
          width={20}
          src={`${appURLs.BATTLES_CDN}/${logoImg}`}
          alt={t("battles:ad.sponsor-logo-alt", "Sponsor logo")}
        />{" "}
        {t("battles:ad.sponsor-tag", "By {{brandName}}", { brandName })}
      </span>
      <p>
        <span>{adText}</span>{" "}
        <a
          className="primary"
          href={`${callToActionUrl}?${REF_PARAM}`}
          style={{ color: "var(--primary)", textDecoration: "underline" }}
          rel="noreferrer"
          target="_blank"
        >
          {t("battles:ad.cta", "Learn more")}
        </a>
      </p>
    </div>
  );
};
