import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import {
  ButtonLoadingSpinner,
  LoadingSpinner,
} from "clutch/src/LoadingSpinner/LoadingSpinner.jsx";
import { t } from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import {
  appURLs,
  GAME_NAME_MAP,
  GAME_SHORT_NAMES,
  hardCodeURLs,
} from "@/app/constants.mjs";
import { BannerAdUnit, NativeAdUnit } from "@/feature-battles/BattlesAds.jsx";
import BattlesEventFaq from "@/feature-battles/BattlesEventFaq.jsx";
import BattlesEventOverview from "@/feature-battles/BattlesEventOverview.jsx";
import BattlesEventRewards, {
  SidebarRewardsInfo,
} from "@/feature-battles/BattlesEventRewards.jsx";
import BattlesEventScoring, {
  SidebarScoringInfo,
} from "@/feature-battles/BattlesEventScoring.jsx";
import { Boltsify, getEventFee } from "@/feature-battles/components/Boltsify";
import { BATTLES_NAME } from "@/feature-battles/constants.mjs";
import { EventTimeline } from "@/feature-battles/EventTimeline.jsx";
import { OPT_IN_STATUS, useOptInStatus } from "@/feature-battles/hooks.mjs";
import { JoinEventModal } from "@/feature-battles/JoinEventModal.jsx";
import { Page, TwoColumnLayout } from "@/feature-battles/Shared.style.jsx";
import { useJoinEvent } from "@/feature-battles/use-join-event.mjs";
import { sponsoredEvent } from "@/feature-battles/utils.mjs";
import SquareCheck from "@/inline-assets/SquareCheck.svg";
import ErrorComponent from "@/shared/ErrorComponent.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { findGameSymbol, useGameSymbol } from "@/util/game-route.mjs";
import { useRoute, useTransientRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Styled = {
  EventCard: styled("div", React.forwardRef)`
    display: flex;
    flex-direction: column;
    gap: var(--sp-4);

    .hero-img {
      border-top-left-radius: var(--br-lg);
      border-top-right-radius: var(--br-lg);

      max-height: 200px;
      width: 100%;
      background-color: var(--shade10);
    }

    .cta {
      margin-top: var(--sp-3);
      min-width: var(--sp-40);
      width: fit-content;

      &.joined {
        color: var(--turq);
        background-color: var(--turq-15);

        & svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  `,
};

const tabs = {
  overview: {
    text: ["battles:tab.overview", "Overview"],
    Component: BattlesEventOverview,
  },
  scoring: {
    text: ["battles:tab.scoring", "Scoring"],
    Component: BattlesEventScoring,
  },
  rewards: {
    text: ["battles:tab.rewards", "Rewards"],
    Component: BattlesEventRewards,
  },
  faq: {
    text: ["battles:tab.faq", "FAQ & Rules"],
    Component: BattlesEventFaq,
  },
};

const BattlesEvent = () => {
  const { t } = useTranslation();
  const {
    parameters: [id, currentTab],
  } = useRoute();
  const route = useTransientRoute();

  const {
    battles: { eventMap },
  } = useSnapshot(readState);
  const event = eventMap[id];
  const [isOpen, setIsOpen] = useState(false);
  const gameSymbol = useGameSymbol();
  const { postJoinEvent } = useJoinEvent(id);

  const handleJoin = async (gameAccountKey) => {
    await postJoinEvent({
      gameAccountKey,
      gameSymbol,
    });
    setIsOpen(false);

    updateRoute(route.currentPath, route.searchParams);
  };

  if (!event) return <LoadingSpinner />;
  if (event instanceof Error || event.game !== gameSymbol) {
    return (
      <ErrorComponent
        description={t("battles:event.error", "Invalid event.")}
      />
    );
  }

  const basePath = `/${BATTLES_NAME}/${GAME_SHORT_NAMES[gameSymbol]}/${id}`;
  const Component = tabs[currentTab].Component;
  event.nativeAdUnit.eventId = id;
  return (
    <>
      <Page>
        <PageHeader
          title={event.displayName}
          links={Object.entries(tabs).map(([key, { text }]) => ({
            text: t(...text),
            url: `${basePath}/${key}`,
          }))}
        >
          <EventCard eventId={id} onJoinEvent={() => setIsOpen(true)} />
        </PageHeader>
        <TwoColumnLayout>
          <div className="span-all">
            <EventTimeline {...event} />
          </div>
          <TwoColumnLayout.Content>
            <Component />
          </TwoColumnLayout.Content>
          <TwoColumnLayout.Sidebar>
            <NativeAdUnit
              sponsoredEvent={sponsoredEvent(event)}
              {...event.nativeAdUnit}
            />
            {currentTab !== "rewards" && <SidebarRewardsInfo event={event} />}
            {currentTab !== "scoring" && <SidebarScoringInfo event={event} />}
          </TwoColumnLayout.Sidebar>
        </TwoColumnLayout>
      </Page>
      <JoinEventModal
        isOpen={event && isOpen}
        event={event}
        closeModal={() => setIsOpen(false)}
        onSubmit={handleJoin}
      />
    </>
  );
};

const optInProps = {
  [OPT_IN_STATUS.ENDED]: {
    as: "div",
    text: ["battles:event.isPast", "Event Has Ended"],
    disabled: true,
  },
  [OPT_IN_STATUS.HAS_JOINED]: {
    as: "div",
    className: "cta joined",
    iconLeft: <SquareCheck />,
    text: ["battles:event.youIn", "You're in!"],
    disabled: true,
  },
  [OPT_IN_STATUS.IS_FULL]: {
    as: "div",
    text: ["battles:event.isFull", "Event Full"],
    disabled: true,
  },
  [OPT_IN_STATUS.JOINABLE]: {
    emphasis: "high",
    text: ["battles:event.join", "Join Event"],
  },
  [OPT_IN_STATUS.LOADING]: {
    emphasis: "high",
    disabled: true,
    isLoading: true,
  },
  [OPT_IN_STATUS.NOT_LOGGED_IN]: {
    href: "/account",
    emphasis: "high",
    text: ["battles:event.login", "Log in to Join"],
  },
  [OPT_IN_STATUS.NOT_INVITED]: {
    as: "div",
    text: ["battles:event.inviteOnly", "Invite Only"],
    disabled: true,
  },
};

function EventCard({ eventId, onJoinEvent }) {
  const { t } = useTranslation();
  const {
    battles: { eventMap },
  } = useSnapshot(readState);
  const event = eventMap[eventId];
  const optInStatus = useOptInStatus(eventId);

  const { text, ...btnProps } = optInProps[optInStatus];
  return (
    <Styled.EventCard>
      {sponsoredEvent(event) ? (
        <BannerAdUnit
          callToActionUrl={event.nativeAdUnit.callToActionUrl}
          eventId={eventId}
          eventPageHeaderImg={event.eventPageHeaderImg}
        />
      ) : (
        <img
          className="hero-img"
          src={`${appURLs.BATTLES_CDN}/${event.eventPageHeaderImg}`}
        />
      )}
      <div className="w-full flex wrap gap-2 justify-between align-start">
        <div className="flex column">
          <h2 className="type-h4">{event.displayName}</h2>
          <span className="type-body1 shade1 flex gap-2 align-center">
            {t("battles:event.feePart", "Entry Fee:")}{" "}
            <Boltsify
              value={
                event.kryptoniteProductId
                  ? event.entryFee
                  : t("common:free", "Free")
              }
            />
          </span>
        </div>
        <div className="flex gap-2">
          <Button
            size="large"
            textClass="type-form--button"
            className="cta"
            {...btnProps}
            {...(optInStatus === OPT_IN_STATUS.JOINABLE && {
              onClick: onJoinEvent,
            })}
          >
            {optInStatus === OPT_IN_STATUS.LOADING ? (
              <ButtonLoadingSpinner />
            ) : (
              t(...text)
            )}
          </Button>
        </div>
      </div>
    </Styled.EventCard>
  );
}

export default BattlesEvent;

/**
 * @returns {import("@/__main__/router.mjs").Meta}
 */
export function meta([eventId]) {
  const gameSymbol = findGameSymbol();

  const event = readState.battles.eventMap[eventId];
  const participantInfo = readState.battles.eventParticipantInfo[eventId];

  const params = {
    eventName: event?.displayName,
    gameName: t(...GAME_NAME_MAP[gameSymbol]),
  };

  const eventUrl = `${appURLs.BLITZ}/${BATTLES_NAME}/${GAME_SHORT_NAMES[gameSymbol]}/${eventId}`;

  return {
    title: [
      "battles:meta.event.title",
      "{{gameName}} {{eventName}} - Blitz Battles",
      params,
    ],
    description: [
      "battles:meta.event.description",
      "Join {{eventName}} and compete with others in {{gameName}} to see if you can earn a piece of the prize pool. Only on Blitz!",
      params,
    ],
    subtitle: [
      "battles:meta.event.subtitle",
      "Join {{eventName}} and compete with others in {{gameName}} to see if you can earn a piece of the prize pool",
      params,
    ],
    image: {
      url: `${appURLs.BATTLES_CDN}/${event?.hubFeaturedImg}`,
      alt: ["battles:meta.event.imgAlt", "{{gameName}} {{eventName}}", params],
      width: 1240,
      height: 120,
    },

    canonicalUrl: eventUrl,

    schema:
      event &&
      participantInfo &&
      createSchema({
        eventUrl,
        description: t(
          "battles:meta.event.subtitle",
          "Join {{eventName}} and compete with others in {{gameName}} to see if you can earn a piece of the prize pool",
          params,
        ),
        gameSymbol,
        event,
        participantInfo,
      }),
  };
}

/**
 * @param {{
 *  eventUrl: string,
 *  description: string,
 *  event: import("@/feature-battles/model-events.mjs").BattlesEvent,
 *  participantInfo: import("@/feature-battles/model-participants-info.mjs").ParticipantInfo,
 * }}
 * @returns {import("@/__main__/router.mjs").Schema}
 */
function createSchema({ eventUrl, description, event, participantInfo }) {
  const { fee, feeCurrency, freeWithPro } = getEventFee(event);

  const hasLimitedCapacity = typeof participantInfo.capacity === "number";

  const isFull =
    hasLimitedCapacity &&
    participantInfo.capacity === participantInfo.participantCount;

  return {
    "@type": "Event",
    eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
    eventStatus: "https://schema.org/EventScheduled",

    name: event.displayName || event.title,
    description: description,

    image: [event.hubFeaturedImg]
      .filter(Boolean)
      .map((file) => `${appURLs.BATTLES_CDN}/${file}`),

    // Careful, calling toISOString may throw an error if these are not valid dates.
    startDate: event.startAt.toISOString(),
    endDate: event.endAt.toISOString(),

    isAccessibleForFree: fee === 0,
    offers: [
      {
        "@type": "Offer",
        name: t("battles:event.entryFeeLabel", "Entry Fee"),
        price: fee,
        availability: !isFull
          ? "http://schema.org/OnlineOnly"
          : "http://schema.org/SoldOut",
        priceCurrency: feeCurrency,
        url: eventUrl,
      },
      !freeWithPro
        ? undefined
        : {
            "@type": "Offer",
            name: t("battles:event.entryFeeProLabel", "FREE with Premium"),
            price: 0,
            availability: !isFull
              ? "http://schema.org/OnlineOnly"
              : "http://schema.org/SoldOut",
            priceCurrency: feeCurrency,
            url: eventUrl,
            eligibleCustomerType: {
              "@type": "BusinessEntityType",
              name: "Premium User",
            },
          },
    ].filter(Boolean),

    ...(hasLimitedCapacity
      ? {
          maximumAttendeeCapacity: participantInfo.capacity,
          maximumVirtualAttendeeCapacity: participantInfo.capacity,
          remainingAttendeeCapacity:
            participantInfo.capacity - (participantInfo.participantCount ?? 0),
        }
      : {}),

    location: {
      "@type": "VirtualLocation",
      url: eventUrl,
    },

    audience: {
      "@type": "Audience",
      name: "Blitz.gg Users",
    },

    organizer: {
      "@type": "Organization",
      name: "Blitz",
      url: appURLs.BLITZ,
      logo: `${appURLs.CDN}/blitz/ui/img/logos/logo-094d65.webp`,
      sameAs: [
        hardCodeURLs.BLITZ_FACEBOOK,
        hardCodeURLs.BLITZ_TWITTER,
        hardCodeURLs.BLITZ_INSTAGRAM,
        hardCodeURLs.BLITZ_DISCORD,
        hardCodeURLs.BLITZ_TIKTOK,
        hardCodeURLs.BLITZ_NAVER,
        hardCodeURLs.BLITZ_MEDIUM,
      ],
    },
  };
}
