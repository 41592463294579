import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import type { TFunction } from "i18next";

import { formatDuration } from "@/app/util.mjs";
import type { BattlesEvent } from "@/feature-battles/model-events.mjs";
import { classNames } from "@/util/class-names.mjs";
import { formatDateTime } from "@/util/i18n-helper.mjs";

const Styled = {
  Timeline: styled("div")`
    width: var(--sp-container);
    padding-bottom: var(--sp-3);

    display: grid;
    grid-template-columns: repeat(18, 1fr);
    column-gap: var(--sp-3);
    row-gap: var(--sp-1);

    .c-1 {
      grid-column: span 2;
    }
    .c-2 {
      grid-column: span 3;
    }
    .c-3 {
      grid-column: span 4;
    }
    .c-4 {
      grid-column: span 7;
    }
    .move-back {
      transform: translateX(-20%);
    }

    .line-group {
      --pnt-color: var(--shade3);

      .point {
        --p-width: var(--sp-4_5);
        position: relative;
        min-width: var(--p-width);
        min-height: var(--p-width);
        width: var(--p-width);
        height: var(--p-width);
        display: flex;
        align-items: center;
        justify-content: center;

        .outer {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1px solid var(--pnt-color);
        }
        .inner {
          position: relative;
          width: var(--sp-2);
          height: var(--sp-2);
          border-radius: 50%;

          background-color: var(--pnt-color);
        }
      }
      .line {
        position: relative;
        width: 100%;
        .bg {
          position: absolute;
          width: 100%;
          height: 1px;

          background-color: var(--shade3);
        }
        .fill {
          position: relative;
          width: 0;
          height: 1px;

          background-color: var(--pnt-color);
        }
      }

      &.active {
        --pnt-color: var(--shade0);

        &.live {
          --pnt-color: var(--turq);
        }

        .inner::after {
          content: "";
          border-radius: 50%;
          position: absolute;
          inset: -1px;
          background-color: var(--pnt-color);
          filter: blur(2px);
        }
        .bg {
          background-color: var(--shade1);
        }
        .fill::after {
          content: "";
          position: absolute;
          inset: -1px 0;
          background-color: var(--pnt-color);
          filter: blur(2px);
        }
      }

      &.hide-line {
        .line {
          display: none;
        }
      }
    }

    .label-group {
      color: var(--shade3);

      .title {
        color: var(--shade1);
      }

      &.active {
        color: var(--shade0);
        &.live {
          .title {
            color: var(--turq);
          }
        }
        .title {
          color: var(--shade0);
        }
      }
    }
  `,
};

// TODO: make sure this is handled for ssr
const dayInMs = 24 * 60 * 60 * 1000;
const dateFormat: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  hour: "numeric",
  timeZoneName: "short",
};
type Point = {
  title: string;
  startAt: Date;
  endAt: Date;
  subtitle: string;
  className: string;
  labelClassName?: string;
  active: boolean;
  percent: string;
};
const getTimeLinePoints = (
  t: TFunction,
  lng,
  { displayStartAt, displayEndAt, startAt, endAt },
): Point[] => {
  const now = new Date();
  const processStart = new Date(endAt.getTime() + dayInMs);
  const processDone = new Date(endAt.getTime() + dayInMs * 8);
  return [
    {
      title: t("battles:timeline.pre.title", "Pre-Registration"),
      startAt: displayStartAt,
      endAt: startAt,
      subtitle:
        now < startAt && startAt.getTime() - now.getTime() < dayInMs
          ? t("battles:timeline.duringPoint", "Ends in {{timer}}", {
              timer: formatDuration(
                startAt.getTime() - now.getTime(),
                "hh:mm:ss",
              ),
            })
          : t("battles:timeline.afterPoint", "Until {{endAt, datetime}}", {
              endAt: startAt,
              formatParams: dateFormat,
            }),
      className: "c-2",
    },
    {
      title: t("battles:timeline.live", "Event is Live!"),
      startAt,
      endAt,
      subtitle:
        now < endAt && endAt.getTime() - now.getTime() < dayInMs
          ? t("battles:timeline.duringPoint", "Ends in {{timer}}", {
              timer: formatDuration(
                endAt.getTime() - now.getTime(),
                "hh:mm:ss",
              ),
            })
          : formatDateTime(lng, startAt, dateFormat),
      className: "c-4 live",
    },
    {
      title: t("battles:timeline.ended", "Event Ends"),
      startAt: endAt,
      endAt: processStart,
      subtitle: formatDateTime(lng, endAt, dateFormat),
      className: "c-2",
    },
    {
      title: t("battles:timeline.processing", "Prizes Processing"),
      subtitle: t("battles:timeline.processing.sub", "Typically takes 1 week."),
      startAt: processStart,
      endAt: processDone,
      className: "c-2",
    },
    {
      title: t("battles:timeline.done", "Prizes Sent Out"),
      subtitle: t("battles:timeline.done.sub", "Check your email."),
      startAt: processDone,
      endAt: displayEndAt,
      className: "c-1 hide-line",
    },
  ].map((p) => {
    const active = p.startAt < now && now < p.endAt;
    const startMs = p.startAt.getTime();
    const adjEndMs = p.endAt.getTime() - startMs;
    const adjNowMs = now.getTime() - startMs;
    const percent = active
      ? `${Math.min(Math.floor((adjNowMs / adjEndMs) * 100), 90)}%`
      : "0";
    const h = { ...p, active, percent };
    return h;
  });
};

export function EventTimeline(eventAsProps: BattlesEvent) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [points, setPoints] = useState(
    getTimeLinePoints(t, language, eventAsProps),
  );

  // Updates every second. If this becomes an issue we can change to every min w/o issue
  useEffect(() => {
    const timeout = setTimeout(
      () => setPoints(getTimeLinePoints(t, language, eventAsProps)),
      1000,
    );
    return () => clearTimeout(timeout);
  }, [eventAsProps, eventAsProps.id, language, points, t]);

  return (
    <div className="w-full scroll-x">
      <Styled.Timeline>
        {points.map((p, i) => (
          <LineGroup key={`line-${i}`} {...p} />
        ))}
        {points.map((p, i) => (
          <LabelGroup key={`lbl-${i}`} {...p} />
        ))}
      </Styled.Timeline>
    </div>
  );
}

function LineGroup({ active, percent, className }: Point) {
  return (
    <div
      {...classNames(
        "flex gap-3 align-center w-full line-group",
        className,
        active && "active",
      )}
    >
      <div className="point">
        <div className="outer" />
        <div className="inner" />
      </div>
      <div className="line">
        <div className="bg" />
        <div className="fill" style={{ width: percent }} />
      </div>
    </div>
  );
}

function LabelGroup({
  title,
  subtitle,
  active,
  className,
  labelClassName,
}: Point) {
  const { t } = useTranslation();
  return (
    <div
      {...classNames(
        "flex column w-full type-caption label-group",
        labelClassName ?? className,
        active && "active",
      )}
    >
      <h5 className="title type-subtitle2">
        <span className="visually-hidden">
          {t("battles:timeline.currentPoint", "current phase:")}
        </span>
        {title}
      </h5>
      <p className="subtitle type-body2-form">{subtitle}</p>
    </div>
  );
}
