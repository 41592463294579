import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Disclosure } from "clutch/src/Accordion/Accordion.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { GAME_BOX_ICONS } from "@/app/constants.mjs";
import { Boltsify } from "@/feature-battles/components/Boltsify.jsx";
import type { MappedParticipantInfo } from "@/feature-battles/hooks.mjs";
import {
  OPT_IN_STATUS,
  useOptInStatus,
  useParticipantInfo,
} from "@/feature-battles/hooks.mjs";
import { Leaderboard } from "@/feature-battles/Leaderboard.jsx";
import type { BattlesEvent } from "@/feature-battles/model-events.mjs";
import {
  ParticipantCount,
  ParticipantIcons,
} from "@/feature-battles/Shared.jsx";
import { getLocalEventQueueInfo } from "@/feature-battles/util-game.mjs";
import GemIcon from "@/inline-assets/event-gem.svg";
import BattlesIcon from "@/inline-assets/nav-battles.svg";
import TrophyIcon from "@/inline-assets/trophy.svg";
import WalletIcon from "@/inline-assets/wallet.svg";
import {
  formatOrdinalNumber,
  formatToFixedNumber,
} from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Styled = {
  TournamentInfo: styled("div")`
    container-name: tournament_info_card;
    container-type: inline-size;

    ul {
      font-size: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: var(--sp-4);
      padding-top: var(--sp-2);

      & > li {
        border-radius: var(--br);
        box-shadow: var(--highlight);
        flex: 1 1 30%;

        padding: var(--sp-6_5);
        display: flex;
        flex-direction: column;
        gap: var(--sp-1);

        background-color: var(--shade6);

        & svg {
          width: var(--sp-5);
          height: var(--sp-5);
        }
      }

      @container tournament_info_card (max-inline-size: 480px) {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
      }
    }

    .cover-image {
      border-radius: var(--br);
      border: 2px solid var(--shade3-15);

      width: 120px;
      height: 70px;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        border-radius: var(--br);
        width: 114px;
        height: 64px;

        background-color: #070e1d;
      }
    }

    .w-fit {
      width: fit-content;
    }

    .map-title {
      @container tournament_info_card (max-inline-size: 480px) {
        display: flex;
        flex-direction: column;

        .cp-btn {
          width: 100%;
        }
      }
    }
  `,
};

const BattlesEventOverview = () => {
  const {
    parameters: [id],
  } = useRoute();
  const {
    battles: { eventMap, userOptIns, eventParticipantInfo },
  } = useSnapshot(readState);

  const event = eventMap[id] as BattlesEvent;
  const participantInfo = useParticipantInfo(
    id,
    eventParticipantInfo,
    userOptIns,
  );

  const userOptedIn = useOptInStatus(id) === OPT_IN_STATUS.HAS_JOINED;

  return (
    <>
      {userOptedIn && participantInfo.userRanking && (
        <CurrentProgressCard event={event} participantInfo={participantInfo} />
      )}
      <TournamentInfoCard
        event={event}
        participantInfo={participantInfo}
        userOptedIn={userOptedIn}
      />
      <Leaderboard eventId={id} />
    </>
  );
};

function CurrentProgressCard({
  event,
  participantInfo,
}: {
  event: BattlesEvent;
  participantInfo: MappedParticipantInfo;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const gameLimit = event.gameLimit;
  const remainingGames = gameLimit - participantInfo.userGameCount;
  return (
    <Disclosure className="bg-shade7 br pad-6" defaultOpen>
      <Disclosure.Button className="shade0 type-article-headline">
        {t("battles:event.currentProgress", "Current Progress")}
      </Disclosure.Button>
      <Disclosure.Panel>
        <Styled.TournamentInfo>
          <ul>
            <li>
              <span className="type-subtitle1 shade0 flex gap-1">
                {formatToFixedNumber(
                  language,
                  participantInfo.userRanking.score || 0,
                )}
                <GemIcon />
              </span>
              <span className="type-caption shade1">
                {t("battles:event.totalPoints", "Total Points")}
              </span>
            </li>
            <li>
              <span className="type-subtitle1 shade0 flex gap-1">
                {formatOrdinalNumber(
                  language,
                  participantInfo.userRanking.rank || 0,
                )}
              </span>
              <span className="type-caption shade1">
                {t("battles:event.currentRank", "Current Rank")}
              </span>
            </li>
            <li>
              <span className="type-subtitle1 shade0 flex gap-1">
                {t(
                  "battles:event.gamesLeft.amount",
                  "{{remainingGames, number}}/{{gameLimit, number}}",
                  { remainingGames, gameLimit },
                )}
              </span>
              <span className="type-caption shade1">
                {t("battles:event.gamesLeft.hint", "Games Left")}
              </span>
            </li>
          </ul>
        </Styled.TournamentInfo>
      </Disclosure.Panel>
    </Disclosure>
  );
}

function formatList(lng, list) {
  return new Intl.ListFormat(lng, {
    style: "short",
    type: "conjunction",
  }).format(list);
}

function TournamentInfoCard({
  event,
  participantInfo,
  userOptedIn,
}: {
  event: BattlesEvent;
  participantInfo: ReturnType<typeof useParticipantInfo>;
  userOptedIn: boolean;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const GameIcon = GAME_BOX_ICONS[event.game];
  const { gameLimit, topGames, totalPrizePool, game } = event;
  const { gameModes, queueTypes } = getLocalEventQueueInfo(
    game,
    event.missions,
  );
  return (
    <Disclosure className="bg-shade7 br pad-6" defaultOpen={!userOptedIn}>
      <Disclosure.Button className="shade0 type-article-headline">
        {t("battles:event.tournamentInfo", "Tournament Info")}
      </Disclosure.Button>
      <Disclosure.Panel>
        <Styled.TournamentInfo>
          <ul>
            <li>
              <TrophyIcon />
              <span className="type-subtitle1 shade0">
                <Boltsify value={totalPrizePool} />
              </span>
              <span className="type-caption shade1">
                {t("battles:prizePool", "Prize Pool")}
              </span>
            </li>
            <li>
              {participantInfo.leaderboard && (
                <ParticipantIcons
                  leaderboard={participantInfo.leaderboard}
                  participantCount={participantInfo.count}
                />
              )}
              <ParticipantCount
                className="type-subtitle1 shade0"
                capacity={participantInfo.capacity}
                count={participantInfo.count}
              />
              <span className="type-caption shade1">
                {t("battles:event.participantCount", "Registered Participants")}
              </span>
            </li>
            <li>
              <WalletIcon />
              <span className="type-subtitle1 shade0">
                <Boltsify
                  value={
                    event.kryptoniteProductId
                      ? event.entryFee
                      : t("common:free", "Free")
                  }
                />
              </span>
              <span className="type-caption shade1">
                {t("battles:event.feeTitle", "Entry Fee")}
              </span>
            </li>

            <li>
              <BattlesIcon />
              {gameLimit === topGames ? (
                <>
                  <span className="type-subtitle1 shade0">
                    {t(
                      "battles:event.gameTotal.title",
                      "{{count, number}} Games",
                      { count: gameLimit },
                    )}
                  </span>
                  <span className="type-caption shade1">
                    {t(
                      "battles:event.gameTotal.hint",
                      "Play {{count, number}} games anytime during the event.",
                      { count: gameLimit },
                    )}
                  </span>
                </>
              ) : (
                <>
                  <span className="type-subtitle1 shade0">
                    {t(
                      "battles:event.bestGameTotal.title",
                      "Best {{count, number}} Games",
                      { count: topGames },
                    )}
                  </span>
                  <span className="type-caption shade1">
                    {t(
                      "battles:event.bestGameTotal.hint",
                      "Play up to {{count, number}} games anytime during the event.",
                      { count: gameLimit },
                    )}
                  </span>
                </>
              )}
            </li>
            <li>
              <GameIcon />
              <span className="type-subtitle1 shade0">
                {formatList(
                  language,
                  gameModes.map((mode) => t(...mode)),
                )}
              </span>
              <span className="type-caption shade1">
                {formatList(
                  language,
                  queueTypes.map((queue) => t(...queue)),
                )}
              </span>
            </li>
          </ul>
        </Styled.TournamentInfo>
      </Disclosure.Panel>
    </Disclosure>
  );
}

export default BattlesEventOverview;
