import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { Boltsify } from "@/feature-battles/components/Boltsify";
import {
  Card,
  CardList,
  InfoSection,
  RankedCard,
} from "@/feature-battles/Shared.jsx";
import { createRewardTiers } from "@/feature-battles/utils.mjs";
import ErrorComponent from "@/shared/ErrorComponent.jsx";
import { formatOrdinalNumber } from "@/util/i18n-helper.mjs";
import { useIsLoaded, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const BattlesEventRewards = () => {
  const {
    parameters: [id],
  } = useRoute();

  const state = useSnapshot(readState);
  const { eventMap } = state.battles;
  const event = eventMap[id];
  const { t } = useTranslation();
  const isLoaded = useIsLoaded();

  const rewardTiers = useMemo(() => createRewardTiers(event?.rewards), [event]);

  if (event instanceof Error || (isLoaded && !rewardTiers.length))
    return (
      <Card className="flex justify-center">
        <ErrorComponent
          description={t("battles:error.noRewardsData", "No Rewards Data.")}
        />
      </Card>
    );

  return (
    <CardList as="ol" variant="table">
      <Card as="li" className="type-subtitle1">
        {t("battles:event.rewards.title", "Rewards")}
      </Card>
      {rewardTiers.map((item, index) => (
        <RewardItem key={index} {...item} />
      ))}
    </CardList>
  );
};

const RewardItem = ({ minRank, maxRank, rewards, ...props }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <RankedCard as="li" className="flex align-start" rank={minRank} {...props}>
      <span className="types-subtitle1">
        {minRank === maxRank
          ? formatOrdinalNumber(language, minRank)
          : t(
              "battles:rewards.tierRange",
              "{{minRank, ordinal}} ~ {{maxRank, ordinal}}",
              { minRank, maxRank },
            )}
      </span>
      <span className="type-caption flex column align-end">
        {rewards.map(({ amount, type }, index) => (
          <span key={index}>
            <Boltsify
              value={`${amount && amount > 1 ? amount.toLocaleString() : ""} ${type}`.trim()}
            />
          </span>
        ))}
      </span>
    </RankedCard>
  );
};

export function SidebarRewardsInfo({ event }) {
  const { t } = useTranslation();
  const rewardTiers = useMemo(() => createRewardTiers(event?.rewards), [event]);

  return (
    <InfoSection title={t("battles:rewards", "Rewards")}>
      {rewardTiers?.length === 0 && (
        <Card className="flex justify-center">
          {t("battles:error.noRewardsData", "No Rewards Data.")}
        </Card>
      )}
      {rewardTiers?.map((item, index) => (
        <RewardItem key={index} {...item} />
      ))}
    </InfoSection>
  );
}

export default BattlesEventRewards;
