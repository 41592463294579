import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Disclosure } from "clutch/src/Accordion/Accordion.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { Accordion, Card } from "@/feature-battles/Shared.jsx";
import { formatBattlesData } from "@/feature-battles/utils/format-battles-data.mjs";
import ErrorComponent from "@/shared/ErrorComponent.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Styled = {
  answerCss: () => css`
    a {
      color: var(--game-color, var(--primary));
      text-decoration: underline;

      &:hover {
        color: var(--shade0);
      }
    }
  `,
};

const BattlesEventFaq = () => {
  const {
    parameters: [id],
  } = useRoute();
  const { t } = useTranslation();

  const {
    battles: { eventMap },
  } = useSnapshot(readState);
  const event = eventMap[id];

  return (
    <>
      <Card className="type-subtitle1">
        {t("battles:event.faq", "FAQ and Rules")}
      </Card>
      {event.assets.faq.length ? (
        <Accordion>
          {event.assets.faq.map(({ question, answer }, index) => (
            <Disclosure key={index}>
              <Disclosure.Button>{question}</Disclosure.Button>
              <Disclosure.Panel className={Styled.answerCss()}>
                {formatBattlesData(event, answer)}
              </Disclosure.Panel>
            </Disclosure>
          ))}
        </Accordion>
      ) : (
        <Card className="flex justify-center">
          <ErrorComponent
            description={t(
              "battles:error.noFaq",
              "There doesn't seem to be anything here yet.",
            )}
          />
        </Card>
      )}
    </>
  );
};

export default BattlesEventFaq;
