import React from "react";

import { REF_PARAM } from "@/feature-battles/constants.mjs";
import type { BattlesEvent } from "@/feature-battles/model-events.mjs";

const splitRegex = /\{\{|\}\}/g;
export function formatBattlesData(event: BattlesEvent, str: string) {
  if (!event) return str;
  const items = str.split(splitRegex);
  if (items.length === 1) return str;

  const out = [];
  let idx = 0;
  for (const item of items) {
    if (item === "sponsor-link")
      out.push(
        React.createElement(
          "a",
          {
            href: `${event.nativeAdUnit.callToActionUrl}?${REF_PARAM}`,
            className: "inline-link",
            key: idx++,
          },
          event.nativeAdUnit.callToActionUrl,
        ),
      );
    else if (event[item]) out.push(event[item].toLocaleString());
    else out.push(item);
  }
  return out;
}
